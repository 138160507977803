import React from 'react'
import dashboardIcon from '../../assets/dashboard-icon.svg'
import servicesIcon from '../../assets/sidenav-1.svg'
import tracesIcon from '../../assets/sidenav-2.svg'
import logsIcon from '../../assets/sidenav-3.svg'
import alertsIcon from '../../assets/settings-icon.svg'
import integrationsIcon from '../../assets/integrations-icon.svg'
import './sidebar.css'

export default function Sidebar() {
    return (
        <div className="sidebar">
            <div className="sidebar-items">
                <img src={servicesIcon} alt="services-icon" />
                <p>Services</p>
            </div>
            <div className="sidebar-items">
                <img src={dashboardIcon} alt="dashboard-icon" />
                <p>Dashboard</p>
            </div>
            <div className="sidebar-items">
                <img src={tracesIcon} alt="traces-icon" />
                <p>Traces</p>
            </div>
            <div className="sidebar-items">
                <img src={logsIcon} alt="logs-icon" />
                <p>Logs</p>
            </div>
            <div className="sidebar-items">
                <img src={alertsIcon} alt="alerts-icon" />
                <p>Alerts</p>
            </div>
            <div className="sidebar-items">
                <img src={integrationsIcon} alt="integration-icon" />
                <p>Integrations</p>
            </div>
        </div>
    )
}