import React from 'react';
import './header.css';
import akiraLogo from '../../assets/akira-ai-blue-logo.svg';
import notificationIcon from '../../assets/notifications-icon.svg'
import modeIcon from '../../assets/dark-mode-icon.svg'
import infoIcon from '../../assets/info-icon.svg'
import profileImage from '../../assets/profile-image.svg'

export default function Header() {
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
      <div className='navbar'>
        <div className='akira-ai-logo'>
          <img src={akiraLogo} alt='akira-ai-logo' onClick={handleLogoClick} />
        </div>
        <div className='menu-items'>
          <i className="notifications-icon">
          <img src={notificationIcon} alt="notification-icon" />
          </i>
          <i className="mode-icon">
          <img src={modeIcon} alt="mode-icon" />
          </i>
          <i className="info-icon">
          <img src={infoIcon} alt="info-icon" />
          </i>
          <img src={profileImage} className='header-profile-image' alt="profile-image" />
        </div>
      </div>
  );
}
