import React, { useState, useEffect } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Header from "../header/header";
import Sidebar from "../sidebar/sidebar";
import './recommendation.css';
import AlertsIcon from "../../assets/alerts-icon.svg";
import LogsIcon from "../../assets/logs-icon.svg";
import CrossIcon from "../../assets/cross-icon.svg";
import RemediationIcon from "../../assets/remediation-icon.svg";
import axios from "axios";
import { REACT_APP_BASE_URL, REACT_APP_DEBUG_API } from "../../config";
import FadeLoader from "react-spinners/FadeLoader";

export default function RemediationPage() {
    const [activeTab, setActiveTab] = useState("alerts");
    const [alertDetails, setAlertDetails] = useState({});
    const [llmResponse, setLlmResponse] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [debugResponse, setDebugResponse] = useState("");
    const [isDebugLoading, setIsDebugLoading] = useState(false);
    const [debugError, setDebugError] = useState("");
    const [typewriterText, setTypewriterText] = useState("");

    const tabs = [
        { name: "Alerts", icon: AlertsIcon, id: "alerts" },
        { name: "Logs", icon: LogsIcon, id: "logs" },
        { name: "Remediation", icon: RemediationIcon, id: "remediation" }
    ];

    useEffect(() => {
        const storedDetails = JSON.parse(localStorage.getItem("selectedAlert"));
        const alertId = localStorage.getItem("selectedAlertId");
        if (storedDetails && alertId) {
            setAlertDetails(storedDetails);
            fetchRecommendation(alertId);
        }
    }, []);

    const fetchRecommendation = async (alertId) => {
        if (!alertId) return;
        setIsLoading(true);
        setError("");
        try {
            const response = await axios.get(`${REACT_APP_BASE_URL}/recommendation?alert_id=${alertId}`);
            const llmResponses = response.data?.data?.map(item => item.llm_response) || [];
            if (llmResponses.length > 0) {
                const formattedResponse = formatLlmResponse(llmResponses.join("\n\n"));
                setLlmResponse(formattedResponse || "No recommendations available.");
            } else {
                setLlmResponse("No recommendations available.");
            }
        } catch (error) {
            console.error("Error fetching recommendation:", error);
            setError("Failed to fetch recommendation.");
        } finally {
            setIsLoading(false);
        }
    };

    const formatLlmResponse = (response) => {
        let formattedResponse = response.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
        formattedResponse = formattedResponse.replace(/\n/g, '<br />');
        return formattedResponse;
    };

    const sendDebugRequest = async () => {
        setIsDebugLoading(true);
        setDebugError("");
        try {
            const payload = {
                request: JSON.stringify(alertDetails, null, 2),
                read_only: true,
                input_mode: "NEVER",
                max_replies: 10
            };
            const response = await axios.post(
                REACT_APP_DEBUG_API + `/debug/`,
                payload
            );
            setDebugResponse(response.data?.debugger_response || "No debugging information available.");
            startTypewriterEffect(response.data?.debugger_response || "No debugging information available.");
        } catch (error) {
            console.error("Error during debugging:", error);
            setDebugError("Failed to fetch debugging information.");
        } finally {
            setIsDebugLoading(false);
        }
    };

    const startTypewriterEffect = (text) => {
        setTypewriterText("");
        let index = 0;
        const interval = setInterval(() => {
            if (index < text.length) {
                setTypewriterText((prev) => prev + text.charAt(index));
                index++;
            } else {
                clearInterval(interval);
            }
        }, 35); 
    };

    const togglePopup = () => {
        if (!isPopupOpen) {
            sendDebugRequest();
        }
        setIsPopupOpen(!isPopupOpen);
    };

    useEffect(() => {
        if (isPopupOpen) {
            Prism.highlightAll();
        }
    }, [isPopupOpen, typewriterText]);

    return (
        <>
            <div className={`app-wrapper ${isPopupOpen ? "blurred-background" : ""}`}>
                <Header />
                <Sidebar />
                <div className="remediation-container">
                    <div className="remediation-header">
                        <h1>Log Details #{alertDetails.id || "Unknown"}</h1>
                        <hr />
                    </div>
                    <div className="tabs-container">
                        {tabs.map((tab) => (
                            <div
                                key={tab.id}
                                className={`tab-item ${activeTab === tab.id ? "active" : ""}`}
                                onClick={() => setActiveTab(tab.id)}
                            >
                                <img src={tab.icon} alt={`${tab.name} icon`} className="tab-icon" />
                                <span className="tab-name">{tab.name}</span>
                            </div>
                        ))}
                    </div>
                    <div className="details-container">
                        <div className="details-header">
                            <h4>Alert Details</h4>
                        </div>
                        <div className="alert-details-container">
                            <p><strong>App:</strong> {alertDetails.app || "N/A"}</p>
                            <p><strong>Container:</strong> {alertDetails.container || "N/A"}</p>
                            <p><strong>Namespace:</strong> {alertDetails.namespace || "N/A"}</p>
                            <p><strong>Filename:</strong> {alertDetails.filename || "N/A"}</p>
                            <p><strong>Log Level:</strong> {alertDetails.log_level || "N/A"}</p>
                            <p><strong>Message:</strong> {alertDetails.message || "N/A"}</p>
                            <p><strong>Timestamp:</strong> {alertDetails.timestamp
                                ? new Date(alertDetails.timestamp).toLocaleString()
                                : "N/A"}
                            </p>
                        </div>

                        <div className="details-header">
                            <h4>Recommendations</h4>
                        </div>
                        <div className="recommendation-details-container">
                            {isLoading ? (
                                <p>Loading recommendations...</p>
                            ) : error ? (
                                <p className="error-text">{error}</p>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: llmResponse }} />
                            )}
                        </div>
                        <button className="debug-button" onClick={togglePopup}>Debug</button>
                    </div>
                </div>
            </div>

            {isPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup">
                        <h2>Debugging
                            <img
                                src={CrossIcon}
                                alt="Close"
                                className="close-icon"
                                onClick={togglePopup}
                            />
                        </h2>
                        <hr />
                        <div className="code-container">
                            {isDebugLoading ? (
                                <FadeLoader
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            ) : debugError ? (
                                <p className="error-text">{debugError}</p>
                            ) : (
                                <pre>
                                    <code className="language-json">{typewriterText}</code>
                                </pre>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
