// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepage-container {
    position: relative;
    margin-left: 290px;
    padding-top: 90px;
    text-align: left;
    width: calc(100% - 330px);
}

.homepage-container h1 {
    font-size: 1.8rem;
}

.use-cases-card-wrapper {
    display: flex;
    gap: 20px;
    margin: 24px 0;
}

.investigation-card{
    display: flex;
    gap: 16px;
    padding: 10px 20px 0;
    border: 1px solid #E8E8ED;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.integration-card {
    display: flex;
    gap: 16px;
    padding: 10px 20px;
    border: 1px solid #E8E8ED;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.integration-card-icon {
    margin-top: 8px;
}

.description-wrapper{
    display: flex;
}

.row-wrapper{
    margin: 26px 0;
}

.card-description button {
    padding: 8px 30px;
    background-color: #352253;
    color: white;
    font-weight: 600;
    border: 1px solid #352253;
    margin-top: 14px;
    border-radius: 6px;
    cursor: pointer;
}

.card-details h3{
    margin: 8px 0 16px;
    font-size: 18px;
}

.card-details p{
    margin-top: 0;
    color: #9095A1;
    font-size: 14px;
}


`, "",{"version":3,"sources":["webpack://./src/components/homepage/homepage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,oBAAoB;IACpB,yBAAyB;IACzB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,yBAAyB;IACzB,wCAAwC;AAC5C;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,eAAe;AACnB","sourcesContent":[".homepage-container {\n    position: relative;\n    margin-left: 290px;\n    padding-top: 90px;\n    text-align: left;\n    width: calc(100% - 330px);\n}\n\n.homepage-container h1 {\n    font-size: 1.8rem;\n}\n\n.use-cases-card-wrapper {\n    display: flex;\n    gap: 20px;\n    margin: 24px 0;\n}\n\n.investigation-card{\n    display: flex;\n    gap: 16px;\n    padding: 10px 20px 0;\n    border: 1px solid #E8E8ED;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.integration-card {\n    display: flex;\n    gap: 16px;\n    padding: 10px 20px;\n    border: 1px solid #E8E8ED;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.integration-card-icon {\n    margin-top: 8px;\n}\n\n.description-wrapper{\n    display: flex;\n}\n\n.row-wrapper{\n    margin: 26px 0;\n}\n\n.card-description button {\n    padding: 8px 30px;\n    background-color: #352253;\n    color: white;\n    font-weight: 600;\n    border: 1px solid #352253;\n    margin-top: 14px;\n    border-radius: 6px;\n    cursor: pointer;\n}\n\n.card-details h3{\n    margin: 8px 0 16px;\n    font-size: 18px;\n}\n\n.card-details p{\n    margin-top: 0;\n    color: #9095A1;\n    font-size: 14px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
