// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar{
    background-color: #352253;
    width: 230px;
    position: fixed;
    height: calc(100vh - 40px);
    top: 77px;
    padding: 1.6rem 0 0 1.6rem;
}
.sidebar-items{
    display: flex;
}
.sidebar-items p{
    color: #fff;
    margin-left: 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/sidebar/sidebar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,0BAA0B;IAC1B,SAAS;IACT,0BAA0B;AAC9B;AACA;IACI,aAAa;AACjB;AACA;IACI,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".sidebar{\n    background-color: #352253;\n    width: 230px;\n    position: fixed;\n    height: calc(100vh - 40px);\n    top: 77px;\n    padding: 1.6rem 0 0 1.6rem;\n}\n.sidebar-items{\n    display: flex;\n}\n.sidebar-items p{\n    color: #fff;\n    margin-left: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
