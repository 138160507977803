// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar{
    position: fixed;
    width: 100%;
    display: flex;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    padding: 1rem 1.6rem;
}

.menu-items {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.notifications-icon, .mode-icon, .info-icon {
    margin-left: 1.4rem;
}

.header-profile-image{
    margin-left: 1.4rem;
}
.dashboard-container hr{
    size: 1px;
    color: #E8E8ED !important;
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,aAAa;IACb,wCAAwC;IACxC,8BAA8B;IAC9B,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,SAAS;IACT,yBAAyB;AAC7B","sourcesContent":[".navbar{\n    position: fixed;\n    width: 100%;\n    display: flex;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    justify-content: space-between;\n    padding: 1rem 1.6rem;\n}\n\n.menu-items {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.notifications-icon, .mode-icon, .info-icon {\n    margin-left: 1.4rem;\n}\n\n.header-profile-image{\n    margin-left: 1.4rem;\n}\n.dashboard-container hr{\n    size: 1px;\n    color: #E8E8ED !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
