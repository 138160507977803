import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./components/homepage/homepage";
import RemediationPage from "./components/recommendation/recommendation";
import AlertsListing from "./components/AlertListing/AlertsListing";
import Dashboard from "./components/dashboard/dashboard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/alerts-listing" element={<AlertsListing />} />
        <Route path="/recommendation" element={<RemediationPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
