import React from "react";
import { Link } from "react-router-dom";
import Header from "../header/header";
import Sidebar from "../sidebar/sidebar";
import './homepage.css';
import cardIcon from '../../assets/integration-card-icon.svg';
import cardImage from '../../assets/homepage-card-image.svg';
import zapierIcon from '../../assets/zapier-icon.png';
import hubspotIcon from '../../assets/hubspot-icon.png';
import instaIcon from '../../assets/insta-icon.png';
import salesforceIcon from '../../assets/salesforce-icon.png';
import '../../common/grid.css'

export default function Homepage() {
    return (
        <div className="app-wrapper">
            <Header />
            <Sidebar />
            <div className="homepage-container">
                <h1>Welcome</h1>
                <hr />
                <div className="use-cases-card-wrapper">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="investigation-card">
                                <div className="card-icon">
                                    <img src={cardIcon} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Investigation and Recommendation</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Create shared inboxes for teammates to work together.</p>
                                            <Link to="/alerts-listing">
                                            <button>Try Now</button>
                                            </Link>
                                        </div>
                                        <div className="card-image">
                                            <img src={cardImage} alt="card-image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="investigation-card">
                                <div className="card-icon">
                                    <img src={cardIcon} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Automation as Code</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Create shared inboxes for teammates to work together.</p>
                                            <button>Try Now</button>
                                        </div>
                                        <div className="card-image">
                                            <img src={cardImage} alt="card-image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h3>
                    Integrate your favourite apps
                </h3>
                <p>Browse through the most commonly enabled plugins in Akira AI.</p>
                <div className="integration-cards-wrapper">
                    <div className="row row-wrapper">
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={zapierIcon} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Zapier</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Connect Front to hundreds of apps with plug-and-play Zaps</p>
                                            <button>Integrate Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={hubspotIcon} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Hubspot</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Connect Front to hundreds of apps with plug-and-play Zaps</p>
                                            <button>Integrate Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={instaIcon} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Instagram</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Connect Front to hundreds of apps with plug-and-play Zaps</p>
                                            <button>Integrate Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={salesforceIcon} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Salesforce</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Connect Front to hundreds of apps with plug-and-play Zaps</p>
                                            <button>Integrate Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row row-wrapper">
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={zapierIcon} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Zapier</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Connect Front to hundreds of apps with plug-and-play Zaps</p>
                                            <button>Integrate Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={hubspotIcon} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Hubspot</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Connect Front to hundreds of apps with plug-and-play Zaps</p>
                                            <button>Integrate Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={instaIcon} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Instagram</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Connect Front to hundreds of apps with plug-and-play Zaps</p>
                                            <button>Integrate Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-3">
                            <div className="integration-card">
                                <div className="integration-card-icon">
                                    <img src={salesforceIcon} alt="card-icon" />
                                </div>
                                <div className="card-details">
                                    <h3>Salesforce</h3>
                                    <div className="description-wrapper">
                                        <div className="card-description">
                                            <p>Connect Front to hundreds of apps with plug-and-play Zaps</p>
                                            <button>Integrate Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
