import React from "react";
import Sidebar from "../sidebar/sidebar";
import Header from "../header/header";

export default function Dashboard (){
    return(
        <div className="dashboard-wrapper">
            <Sidebar />
            <Header />
        </div>
    )
}