import React, { useState, useEffect } from "react";
import './AlertsListing.css';
import Header from "../header/header";
import Sidebar from "../sidebar/sidebar";
import AlertsIcon from "../../assets/alerts-icon.svg";
import LogsIcon from "../../assets/logs-icon.svg";
import RemediationIcon from "../../assets/remediation-icon.svg";
import TeamsIcon from "../../assets/teams-icon.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../config";

export default function AlertsListing() {
    const [activeTab, setActiveTab] = useState("alerts");
    const [alertData, setAlertData] = useState([]);
    const [apiResponse, setApiResponse] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const tabs = [
        { name: "Alerts", icon: AlertsIcon, id: "alerts" },
        { name: "Logs", icon: LogsIcon, id: "logs" },
        { name: "Remediation", icon: RemediationIcon, id: "remediation" }
    ];

    useEffect(() => {
        if (activeTab === "alerts") {
            fetchAlertsData();
        }
    }, [activeTab]);

    const fetchAlertsData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(REACT_APP_BASE_URL + "/alerts");
            const data = Array.isArray(response.data.data) ? response.data.data : [];
            setApiResponse(data);
            localStorage.setItem("alertsApiResponse", JSON.stringify(data));
            const transformedData = data.map((item) => ({
                id: item.id || "N/A",
                description: item.message ? item.message.replace(/^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}\s*/, '') : "N/A",
                type: item.log_level || "N/A",
                app: item.app || "N/A",
                timestamp: item.timestamp || "N/A",
                contactPoint: TeamsIcon,
            }));
            setAlertData(transformedData);
        } catch (error) {
            console.error("Error fetching alert data:", error);
            setAlertData([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRowClick = (alert) => {
        const selectedAlert = apiResponse.find((item) => item.id === alert.id);
        if (selectedAlert) {
            localStorage.setItem("selectedAlert", JSON.stringify(selectedAlert));
            localStorage.setItem("selectedAlertId", selectedAlert.id);
            navigate("/recommendation");
        }
    };


    const staticTableRows = 5;
    const tableRows = alertData.length > 0
        ? alertData
        : Array(staticTableRows).fill({
            description: "N/A",
            type: "N/A",
            app: "N/A",
            timestamp: "N/A",
            contactPoint: null,
        });

    return (
        <div className="app-wrapper">
            <Header />
            <Sidebar />
            <div className="dashboard-container">
                <div className="remediation-header">
                    <h1>Log Analysis</h1>
                    <hr />
                </div>
                <div className="tabs-container">
                    {tabs.map((tab) => (
                        <div
                            key={tab.id}
                            className={`tab-item ${activeTab === tab.id ? "active" : ""}`}
                            onClick={() => setActiveTab(tab.id)}
                        >
                            <img src={tab.icon} alt={`${tab.name} icon`} className="tab-icon" />
                            <span className="tab-name">{tab.name}</span>
                        </div>
                    ))}
                </div>
                <div className="tab-content">
                    {activeTab === "alerts" && (
                        <div>
                            <h3>Received Alerts</h3>
                            {isLoading ? (
                                <p>Loading...</p>
                            ) : (
                                <table className="alerts-table">
                                    <thead>
                                        <tr>
                                            <th>Alert ID</th>
                                            <th>Description</th>
                                            <th>Type</th>
                                            <th>App</th>
                                            <th>Timestamp</th>
                                            <th>Contact Point</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableRows.map((alert, index) => (
                                            <tr
                                                key={index}
                                                onClick={() => handleRowClick(alert)}
                                                className="clickable-row"
                                            >
                                                <td>{alert.id}</td>
                                                <td>{alert.description}</td>
                                                <td className={`alert-type ${alert.type.toLowerCase()}`}>
                                                    {alert.type}
                                                </td>
                                                <td>{alert.app}</td>
                                                <td>
                                                    {alert.timestamp !== "N/A"
                                                        ? new Date(alert.timestamp).toLocaleString()
                                                        : "N/A"}
                                                </td>
                                                <td>
                                                    {alert.contactPoint ? (
                                                        <img
                                                            src={alert.contactPoint}
                                                            alt="Contact Point icon"
                                                            className="contact-icon"
                                                        />
                                                    ) : (
                                                        "N/A"
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    )}
                    {activeTab === "logs" && <div>Showing Logs content...</div>}
                    {activeTab === "remediation" && <div>Showing Remediation content...</div>}
                </div>
            </div>
        </div>
    );
}
